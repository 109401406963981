import { PATIENT_QUICK_VIEW_SUMMARY, PATIENT_PAIN_SCORE, DEFAULT_URL } from "services/constants";
import { getAuthValues } from "utils/webContainerAuth";
import axios from "axios";

export const getPatientQuickViewSummary = (userProcedureId) => {
  const url = DEFAULT_URL + PATIENT_QUICK_VIEW_SUMMARY.replace(":user_procedure_id", userProcedureId);
  return axios.get(`${url}`, {
    headers: getAuthValues(),
  });
};


export const getPatientPainScore = (userProcedureId) => {
  const url = DEFAULT_URL + PATIENT_PAIN_SCORE.replace(":user_procedure_id", userProcedureId);
  let authValues = getAuthValues();

  let logObject = hasNullOrUndefinedValue(authValues);
  if (logObject.isNull) {
    throw new Error(`Values are blank, undefined or null for ${logObject.resultString}`);
  }

  return axios.get(`${url}`, {
    headers: authValues,
  });
};

export const hasNullOrUndefinedValue = (obj) => {
  let resultString = [];
  let isNull = false;
  
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] == '' || obj[key] == 'null' || obj[key] == 'undefined') {
      resultString.push(key)
      isNull = true;
    }
  }
  return {resultString: resultString.join(','), isNull: isNull};
}