export const getAuthTokens = () => {
  let tokens = document.cookie
    .split(";")
    .map((cookies) => cookies.trim())
    .filter(
      (cookie) =>
        cookie.includes("Client=") ||
        cookie.includes("Access-Token=") ||
        cookie.includes("Uid=")
    )
    .map((x) => x.split("="))
    .map((x) => ({ [x[0]]: x[1] }))
    .reduce((x, y) => Object.assign(x, y), {});
  return tokens;
};
