import axios, { nonMasqueradingInstance } from '../base';
import {
  SIGNUP_URL,
  GET_VERIFICATION_CODE,
  VERIFY_CODE,
  GENERATE_OTP,
} from '../constants';

export const registerNewUser = (params) => axios.post(SIGNUP_URL, params);

export const generateOtp = (phone) => {
  const params = { contact_number: phone.replaceAll(' ', '').replace('+1', '') };
  return axios.post(GENERATE_OTP, params);
};

export const getVerificationCode = (params) => nonMasqueradingInstance.post(GET_VERIFICATION_CODE, params);

export const verifyCode = (params) => nonMasqueradingInstance.post(VERIFY_CODE, params);
