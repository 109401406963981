import { getAuthTokens } from "utils/getAuthToken";
import axios from "axios";
import {
  DEFAULT_URL,
  GET_NEW_DEVICE_ORDER_INFO_REQUEST,
  GET_DEVICE_PARTS,
  PLACE_NEW_DEVICE_ORDER_REQUEST
} from "services/constants";

export const getAuthValues = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const tokens = getAuthTokens();
  const token = tokens["Access-Token"] || searchParams.get("Access-Token");
  const uid = tokens["Uid"] || searchParams.get("Uid");
  const client = tokens["Client"] || searchParams.get("Client");
  const version = searchParams.get("X-APP-VERSION") || "0";
  const platform = searchParams.get("platform") || searchParams.get("Platform") || "ios";
  axios.defaults.headers.common.Platform = platform;

  return {
    "token-type": "Bearer",
    "access-token": token,
    uid: uid,
    client: client,
    version: version,
  };
};

export const getOrderSummaryAndData = () => {
  return axios.get(`${DEFAULT_URL + GET_NEW_DEVICE_ORDER_INFO_REQUEST}`, {
    headers: getAuthValues(),
  });
};

export const getDeviceParts = (params) => {
  return axios.get(`${DEFAULT_URL + GET_DEVICE_PARTS}`, {
    headers: getAuthValues(),
    params
  });
};

export const placeNewDeviceOrder = (data) => {
  return axios.post(`${DEFAULT_URL + PLACE_NEW_DEVICE_ORDER_REQUEST}`, data, {
    headers: getAuthValues(),
  });
};