import { getAuthTokens } from "utils/getAuthToken";
import axios from "axios";

// searchParams is used for running components locally.
export const getAuthValues = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const tokens = getAuthTokens();
  const token = tokens["Access-Token"] || searchParams.get("Access-Token");
  const uid = tokens["Uid"] || searchParams.get("Uid");
  const client = tokens["Client"] || searchParams.get("Client");
  const version = searchParams.get("X-APP-VERSION") || "0";
  const platform = searchParams.get("platform") || searchParams.get("Platform")|| "ios";
  axios.defaults.headers.common.Platform = platform;
  
  return {
    "token-type": "Bearer",
    "access-token": token,
    uid: uid,
    client: client,
    version: version,
  };
};
