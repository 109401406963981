import { getItemFromStorage } from 'services/storage';
import { proxyAuthHeaderKeys } from 'services/constants';
import { SORTING_STATUSES } from 'patientOrderSupport/containers/Dashboard/configs';

export const isMasquerading = () => {
  let isMasqueradingSession = true;
  proxyAuthHeaderKeys.forEach((key) => {
    if (getItemFromStorage(key)) {
      isMasqueradingSession = isMasquerading && true;
    } else {
      isMasqueradingSession = false;
    }
  });
  return isMasqueradingSession;
};


export const isiOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const arrayOfObjectsAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (const obj1 of arr1) {
    const matchingObj2 = arr2.find(obj2 => {
      return Object.keys(obj1).every(key => Object.is(obj1[key], obj2[key]));
    });
    if (!matchingObj2) {
      return false;
    }
  }

  return true;
}

export const throttle = (cb, delay) => {
  let timer;

  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(timer);

    timer = setTimeout(() => {
      cb.apply(context, args);
    }, delay);
  };
};

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const sortAsc = (field, isDateField) => {
  return function (a, b) {
    let value1 = isDateField ? Date.parse(a[field]) : a[field]
    let value2 = isDateField ? Date.parse(b[field]) : b[field]

    if (value1 > value2) {
      return 1;
    }
    if (value1 < value2) {
      return -1;
    }
    return 0;
  };
};

const sortDesc = (field, isDateField) => {
  return function (a, b) {
    let value1 = isDateField ? Date.parse(a[field]) : a[field]
    let value2 = isDateField ? Date.parse(b[field]) : b[field]

    if (value1 > value2) {
      return -1;
    }
    if (value1 < value2) {
      return 1;
    }
    return 0;
  };
};

// below function is used to sort a js object based on any particular key of object
// if order is true, it sorts in ascending order and vice versa
export const sortData = (data, field, order = SORTING_STATUSES.NONE, isDateField = false) => {
  let newData = [...data];
  if (order === SORTING_STATUSES.ASC) {
    return [...newData].sort(sortAsc(field, isDateField));
  } else if (order === SORTING_STATUSES.DESC) {
    return [...newData].sort(sortDesc(field, isDateField));
  }
  else {
    return data
  }
};
